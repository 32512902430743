<template>
  <div>
    <div class="bg"></div>
    <vue-typed-js
    :strings="['Error 404']"
    :typeSpeed="100"
    :backSpeed="80"
    :backDelay="2000"
    :startDelay="500"
    cursorChar="_">
        <h1 class="typing"></h1>
    </vue-typed-js>
    <p>The page you were looking for wasn't found.</p>
    <p><router-link id="back" title="◄ go back" to="/">◄ back</router-link></p>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer'
  export default {
    name: '404',
    components: {
      Footer,
    }
  }
</script>

<style scoped>
* {
  color:white;
  background-color: none;
}
.typed-element .typed-cursor {
  color: white;
}
h1 {
  color: white;
}
p {
  color: white;
  font-size: 300%;
  margin: 0;
}
.bg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
  background-color: #333333;
}
a {
  text-decoration: none;
  color: white;

}

a:hover {
  text-decoration: none;
  color: #4674b8;
  background-color: #333333;
}

a:hover::before {
  content:'> ';
}

#back:hover::before {
  content: none;
}
</style>